import { loginWx, loginGoogle, loginTikTok, mobileLogin } from '@/api/login';
import { useUrlSearchParams } from '@vueuse/core';
import { onBeforeMount } from 'vue';
import { to } from './to';
// import { fetchUserInfo } from '@/hooks/user';
import { useLoading } from './loading';
import { useDialog } from './dialog';
import { setToken, useUser } from './user';
import { request } from '@/libs/request';
import { getDistributionParam } from '@/api/user';
import { useRouter } from 'vue-router';

// import { useRouter } from 'vue-router';
/** 公众号APPID */
const appId = 'wx5fa1a3d1ccdd53de';

/** 重定向回来的地址 */
// const redirectUrl = encodeURIComponent('https://dev.app.gblinker.com?page=/login');

/** 微信授权页地址 */
const loginUrl = (redirect: string) =>
  `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(
    redirect
  )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;

/** 跳转到微信授权页面 */
export const jumpToWxAuthPage = () => {
  // const redirectUrl = `${location.origin}?page=login&redirect=${encodeURIComponent(location.href)}`;
  const redirectUri = `${location.origin}?page=Login`;
  location.replace(loginUrl(redirectUri));
};

export const setDistributionParam = async () => {
  await getDistributionParam()
    .then((res) => {
      if (res) {
        const { extra } = res;
        localStorage.setItem('extra_item', extra);
      }
    })
    .catch((err) => console.log(err));
};

/** 用户登录获取token */
export const login = async () => {
  const params = useUrlSearchParams('history');
  const { code } = params as { code: string };
  if (!code) {
    return jumpToWxAuthPage();
  }
};
/** useLogin */
export const useLogin = async () => {
  // const router = useRouter();
  const { setToken } = useUser();
  const { showLoading, hideLoading } = useLoading();
  onBeforeMount(async () => {
    const params = useUrlSearchParams('history');
    const {
      code,
      scene = undefined,
      redirect = undefined,
    } = params as { code: string; redirect: string; scene: string };
    if (!code) {
      // await fetchUserInfo();
      redirect &&
        localStorage.setItem('lastPage', decodeURIComponent(redirect));
      return;
    }
    showLoading('登录中');
    const [err, res] = await to(loginWx(code, scene));
    hideLoading();
    if (err) {
      /** todo 登录失败需要弹窗报错 */
      throw new EvalError('code is not found');
    }
    setToken(res.token);
    await setDistributionParam();
    jumpToLastPage();
    // router.replace({
    //   name: 'Home',
    // });
  });
};
/** 获取google access_token */
export const getGoogleAccessToken = async () =>
  new Promise<{ accessToken: string }>((resolve, reject) => {
    if (!gapi) {
      return reject('目前该地区暂不可用.');
    }
    if (!gapi.auth2) {
      return reject('目前该地区暂不可用.');
    }
    gapi.auth2.authorize(
      {
        client_id:
          '112709242649-skijnkvnch8vas85h3bs62nilkdp3me3.apps.googleusercontent.com',
        scope: 'email profile openid',
        response_type: 'id_token permission',
      },
      (response) => {
        if (response.error) {
          reject(response.error);
        }
        // The user authorized the application for the scopes requested.
        const accessToken = response.access_token;
        const idToken = response.id_token;
        console.log(accessToken, idToken);
        // You can also now use gapi.client to perform authenticated requests.
        resolve({
          accessToken: response.access_token,
        });
      }
    );
  });
/** google 登录 */
export const getGoogleLoginCode = async () => {
  const { showLoading, hideLoading } = useLoading();
  const { showDialog } = useDialog();
  showLoading('登录中');
  const [err, res] = await to(getGoogleAccessToken());
  if (err) {
    hideLoading();
    const errMsg = typeof err === 'string' ? err : err.message;
    return showDialog({
      content: errMsg || 'google登录失败，请重新登录',
      btns: [
        {
          text: '知道了',
        },
      ],
    });
  }
  const [loginErr, loginRes] = await to(loginGoogle(res.accessToken));
  if (loginErr) {
    hideLoading();
    showDialog({
      content: '登录失败，请重新登录',
      btns: [
        {
          text: '知道了',
        },
      ],
    });
    throw '登录失败';
  }
  localStorage.setItem('token', loginRes.token);
  await setDistributionParam();
  jumpToLastPage();
};
export const getInitInfo = async () => {
  const data = await request({
    url: '/v1/user/wechat/qrcode-login/init',
    method: 'get',
  });
  return data;
};

/** 访问来源*/
export const getSource = () => {
  const params = useUrlSearchParams('history');
  const { source = undefined } = params as { source: string };
  if (source) {
    localStorage.setItem('source', source);
    return source;
  }
};

// 登录后跳转到最后一个页面
export const jumpToLastPage = () => {
  const lastPage = localStorage.getItem('lastPage');
  if (lastPage) {
    localStorage.removeItem('lastPage');
    location.replace(location.origin + lastPage);
    return;
  }
  location.replace(location.origin);
};
/**
 * 获取访问来源以及其他参数
 */
export const getExtraParams = () => {
  const params = useUrlSearchParams('history');
  const { extra = undefined, source = undefined } = params as {
    extra: string;
    source: string;
  };
  if (extra) {
    localStorage.setItem('extra', extra);
  }
  if (source) {
    localStorage.setItem('source', source);
  }
};

export const redirectToTikTok = () => {
  const redirectUri = `${location.origin}/tiktok-login`;
  const state = Math.random().toString(36).substring(2);
  const url = `https://www.tiktok.com/auth/authorize?client_key=awqhfrku31thjlqe&response_type=code&scope=user.info.basic&redirect_uri=${redirectUri}&state=${state}`;
  location.replace(url);
};

export const loginByTikTok = async () => {
  const { showLoading, hideLoading } = useLoading();
  const { showDialog } = useDialog();
  const router = useRouter();
  const params = useUrlSearchParams('history');
  const { code } = params as { code: string };
  if (!code) {
    location.replace(location.origin);
    return;
  }
  showLoading('登录中');
  const [err, res] = await to(loginTikTok(code));
  hideLoading();
  if (err) {
    const errMsg = typeof err === 'string' ? err : err.message;
    return showDialog({
      content: errMsg || '登录失败,请重新登录',
      btns: [
        {
          text: '知道了',
          fn: () => {
            location.replace(location.origin);
            router.push({
              name: `${location.origin}?page=Login`,
            });
          },
        },
      ],
    });
  }
  console.log(res);
  setToken(res.token);
  await setDistributionParam();
  jumpToLastPage();
};

export const loginWithMobile = async (mobile: string, verify_code: string) => {
  const { showLoading, hideLoading } = useLoading();
  showLoading('登录中');
  const [err, res] = await to(mobileLogin(mobile, verify_code));
  console.log(err, res);
  hideLoading();
  if (err) {
    throw err;
  }
  console.log(res);
  setToken(res.token);
  await setDistributionParam();
  jumpToLastPage();
};
