
import { defineComponent } from 'vue';
import { useToast } from '../../hooks/toast';
export default defineComponent({
  name: 'Toast',
  setup: () => {
    const { isShowToast, toastText, iconClass } = useToast();
    return { isShowToast, toastText, iconClass };
  },
});
