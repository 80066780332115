
import { defineComponent } from 'vue';
import TabBarContainer from './tab-bar.vue';
import TabBarItem from './tab-bar-item.vue';
import { useTabbar } from '@/hooks/tabbar';
export default defineComponent({
  name: 'TabBar',
  components: {
    TabBarContainer,
    TabBarItem,
  },
  setup: () => {
    const { tabbarConfig } = useTabbar();
    return {
      tabbarConfig,
    };
  },
});
