import { useStore } from '@/stores/module/userInfo';
import { bindMobile, bindTeamQrcode, sendSmsCode } from '@/api/user';
import { BindMobileParams, SmsCodeParams, SmsType } from '@/types/user';
import { ref, ComputedRef, computed } from 'vue';
import { useCountry } from './country';
import { storeToRefs } from 'pinia';
import { loginWithMobile } from './login';
import { useLoading } from './loading';
import { useDialog } from './dialog';
import { to } from './to';
import { useToast } from './toast';
/** 获取用户信息 */
export const fetchUserInfo = async () => {
  const store = useStore();
  await store.fetchUserInfo();
};

/** 发送短信验证码 */
export const fetchSendSmsCode = async (params: SmsCodeParams) =>
  await sendSmsCode(params);

/** 绑定用户手机 */
export const fetchBindMobile = async (params: BindMobileParams) =>
  await bindMobile(params);

export const setToken = (token: string) => {
  const store = useStore();
  store.setLogin(token);
};
/** useBindPhone */
export const userBindMobile = () => {
  const { countryList } = useCountry();
  const phoneNumber = ref('');
  const verifyCode = ref('');
  const selectCountryId = ref('CN');
  const smsType: ComputedRef<SmsType> = computed(() => {
    if (selectCountryId.value === 'CN') {
      return SmsType.CN;
    }
    return SmsType.Other;
  });
  const curOption = computed(() => {
    const option = pickerOptions.value.find(
      (item) => item.id === selectCountryId.value
    );
    return option;
  });
  const smsTypeText = computed(() => curOption.value?.country);
  const pickerOptions = computed(() => {
    const config = countryList.value.map((item) => ({
      id: item.id,
      name: `${item.nameCn} ${item.phonePrefix}`,
      value: item.id,
      country: item.nameCn,
      phonePrefix: item.phonePrefix,
    }));
    return [
      {
        id: 'CN',
        name: '中国大陆 +86',
        value: 'CN',
        country: '中国大陆',
        phonePrefix: '+86',
      },
      ...config,
    ];
  });

  const isShowPicker = ref(false);
  const onClosePicker = () => {
    isShowPicker.value = false;
  };

  const fetchSendSmsCode = async () =>
    await sendSmsCode({
      mobile: curOption.value?.phonePrefix + phoneNumber.value,
      smsType: smsType.value,
    });

  const fetchBindMobile = async () => {
    await bindMobile({
      mobile: curOption.value?.phonePrefix + phoneNumber.value,
      verifyCode: verifyCode.value,
    });
    await fetchUserInfo();
  };

  const fetchMobileLogin = async () => {
    return await loginWithMobile(
      curOption.value?.phonePrefix + phoneNumber.value,
      verifyCode.value
    );
  };

  return {
    smsType,
    smsTypeText,
    selectCountryId,
    isShowPicker,
    pickerOptions,
    phoneNumber,
    verifyCode,
    onClosePicker,
    fetchSendSmsCode,
    fetchBindMobile,
    fetchMobileLogin,
  };
};

export const userHandleBindMobileModle = () => {
  const isShowBindMobileModel = ref(false);
  const toggleModel = () => {
    isShowBindMobileModel.value = !isShowBindMobileModel.value;
  };
  return {
    isShowBindMobileModel,
    toggleModel,
  };
};
export const useUser = () => {
  const store = useStore();
  const { isLogin } = storeToRefs(store);

  const dialogVisiable = ref(false);
  const leaderCode = ref('');
  const { showLoading, hideLoading } = useLoading();
  const { showDialog } = useDialog();
  const { showToast } = useToast();
  return {
    setToken,
    isLogin,
    dialogVisiable,
    leaderCode,
    bindLeaderCode: async () => {
      if (!leaderCode.value.trim()) {
        return showDialog({
          content: '请输入邀请码',
        });
      }
      showLoading();
      const [err] = await to(bindTeamQrcode(leaderCode.value));
      hideLoading();
      if (err) {
        const errMsg = typeof err === 'string' ? err : err.message;
        return showDialog({
          content: errMsg || '绑定失败',
          btns: [
            {
              text: '知道了',
            },
          ],
        });
      }
      dialogVisiable.value = false;
      store.fetchUserInfo();
      showToast('绑定成功');
    },
  };
};
