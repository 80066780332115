import { defineStore } from 'pinia';
import { qryUserInfo } from '@/api/user';
import { UserInfo } from '@/types/user';

type State = {
  userInfo: UserInfo | null;
  isLogin: Boolean;
};
export const useStore = defineStore('userInfo', {
  state: (): State => ({
    userInfo: {
      id: 0,
      code: '',
      parentId: 0,
      balance: 0,
      grandParentId: 0,
      distributorLevelId: 0,
      mobile: '',
      info: {
        avatarUrl: require('@/assets/logo.png'),
        city: '',
        country: '',
        language: '',
        nickname: '',
        openid: '',
        privilege: [],
        province: '',
        sex: 0,
        unionid: '',
      },
      name: '',
      role: 0,
      orderAmount: 0,
      checkedCourse: 0,
      point: 0,
      status: 0,
      userCode: '-',
      parentInfo: {
        image: null,
        name: '',
        parent_code: '',
      },
      hasOrder: 0,
    },
    isLogin: Boolean(localStorage.getItem('token')),
  }),
  actions: {
    async fetchUserInfo() {
      this.userInfo = await qryUserInfo();
    },

    setLogin(token: string) {
      localStorage.setItem('token', token);
      this.isLogin = Boolean(localStorage.getItem('token'));
    },
  },
  getters: {
    hasMobile(state): Boolean {
      return Boolean(state.userInfo?.mobile);
    },
    hasUserInfo(state): Boolean {
      return Boolean(state.userInfo);
    },
  },
});
