import { request } from '@/libs/request';
import { BindMobileParams, SmsCodeParams, UserInfo } from '@/types/user';
/** 获取用户信息 */
export const qryUserInfo = async (): Promise<UserInfo> => {
  const res = await request({
    url: '/v1/user',
    method: 'GET',
  });
  return {
    id: res.id,
    code: res.code,
    parentId: res.parent_id,
    balance: res.balance,
    grandParentId: res.grand_parent_id,
    distributorLevelId: res.distributor_level_id,
    mobile: res.mobile,
    info: JSON.parse(res.info),
    name: res.name,
    role: res.role,
    orderAmount: res.order_amount,
    checkedCourse: res.checked_course,
    point: res.point,
    status: res.status,
    userCode: res.user_code,
    parentInfo: {
      ...res.parent_info,
    },
    hasOrder: res.have_order,
  };
};

/** 发送手机验证码 */
export const sendSmsCode = async (params: SmsCodeParams): Promise<void> => {
  const res = await request({
    url: '/v1/user/user/send-verify-code',
    method: 'GET',
    params: {
      mobile: params.mobile,
      sms_type: params.smsType,
    },
  });
  return res;
};

/** 绑定用户手机号码 */
export const bindMobile = async (params: BindMobileParams): Promise<void> => {
  const res = await request({
    url: '/v1/user/user/binding/mobile',
    method: 'POST',
    data: {
      mobile: params.mobile,
      verify_code: params.verifyCode,
    },
  });
  return res;
};
/**
 * 加密后分销的参数
 * @returns
 */
export const getDistributionParam = async (): Promise<any> => {
  const res = await request({
    url: '/v1/user/distribution-param',
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return res;
};
/**
 * 绑定邀请码
 * @param leaderCode
 * @returns
 */
export const bindTeamQrcode = async (leaderCode: string): Promise<any> => {
  const res = await request({
    url: '/v1/user/distributor/binding',
    method: 'POST',
    data: {
      parent_user_code: leaderCode,
    },
  });
  return res;
};
