import { createApp } from 'vue';
import App from './App.vue';
import { setupRouter } from './router';
import { setupStore } from './stores';
import { setupI18n } from './i18n';
import 'weui';
import 'ant-design-vue/dist/antd.css'; // or 'ant-design-vue/dist/antd.less'
import VueCreditCardValidation from 'vue-credit-card-validation';
import VConsole from 'vconsole';

if (['localhost', 'dev.app.gblinker.com'].includes(location.hostname)) {
  console.log(location.hostname);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const vConsole = new VConsole();
}

const app = createApp(App);
setupRouter(app);
setupStore(app);
setupI18n(app);
app.use(VueCreditCardValidation);
app.mount('#app');
