import { ref } from 'vue';

const isShowLoading = ref(false);
const loadingText = ref('加载中');
export const useLoading = () => {
  const showLoading = (text?: string) => {
    loadingText.value = text || '加载中';
    isShowLoading.value = true;
  };
  const hideLoading = () => {
    isShowLoading.value = false;
  };
  return {
    isShowLoading,
    showLoading,
    hideLoading,
    loadingText,
  };
};

// export const install = (app: App) => {
//   const { showLoading, hideLoading } = useLoading();
//   app.config.globalProperties.showLoading = showLoading;
//   app.config.globalProperties.hideLoading = hideLoading;
// };
