
import { defineComponent } from 'vue';
import Icon from '@/components/common/icon.vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'MNavigationBar',
  components: {
    Icon,
  },
  setup: () => {
    const router = useRouter();
    return {
      itemClick: () => {
        router.back();
      },
    };
  },
});
