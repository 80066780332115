import { RouteRecordName } from 'vue-router';
type TabbcarConfig = {routeName: RouteRecordName, iconPath: string, selectedIconPath: string, text: string}[];
export  const TABBAR_CONFIG: TabbcarConfig  = [
  {
    routeName: 'Home',
    iconPath: require('@/assets/tabbar/home-unselected.png'),
    selectedIconPath: require('@/assets/tabbar/home-selected.png'),
    text: '主页',
  },
  {
    routeName: 'Order',
    iconPath: require('@/assets/tabbar/do-order-unselected.png'),
    selectedIconPath: require('@/assets/tabbar/do-order-selected.png'),
    text: '寄包裹',
  },
  {
    routeName: 'OrderList',
    iconPath: require('@/assets/tabbar/package-list-unselected.png'),
    selectedIconPath: require('@/assets/tabbar/package-list-selected.png'),
    text: '包裹列表',
  },
  {
    routeName: 'Mine',
    iconPath: require('@/assets/tabbar/my-profile-unselected.png'),
    selectedIconPath: require('@/assets/tabbar/my-profile-selected.png'),
    text: '我的',
  },
];
