import { request } from '@/libs/request';
import { CountryItem, CountryState, MixCountryItem } from '@/types/country';

/** 获取国家列表 */
export const getCountryConfig = async (): Promise<CountryItem[]> => {
  const data: {
    [key: string]: Omit<MixCountryItem, 'nameCn' | 'phonePrefix'>
  } = await request({
    url: 'https://res-cn.public.gblinker.com/gblinker/wechat_mini/data/states.json',
    method: 'GET',
  });
  const countryList = Object.values(data);
  // const list =  countryList.filter(item => item.miniprogram_order_plugins.length === 0);
  return countryList.map((item) => {
    const states: CountryState = item.states.map(item => ({ id: item.id, name: item.name, nameCn: item.name_cn }));
    return {
      icon: item.icon,
      id: item.id,
      name: item.name,
      states,
      nameCn: item.name_cn,
      phonePrefix: item.phone_prefix,
      orderPlugins: item.miniprogram_order_plugins,
    };
  });
};
