
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue';
import TabBar from '@/components/common/tabbar/index.vue';
import PcHeader from '@/components/common/pc-header.vue';
import Toast from '@/components/common/toast.vue';
import Loading from '@/components/common/loading.vue';
import Dialog from '@/components/common/dialog.vue';
import { useTabbar } from '@/hooks/tabbar';
import { useMediaQuery } from '@vueuse/core';
import { useUser, fetchUserInfo } from '@/hooks/user';
import { getExtraParams } from './hooks/login';
import MNavigationBar from '@/components/common/m-navigation-bar.vue';
import { isMobile } from './libs/utils';
// import { useRouter } from 'vue-router';
export default defineComponent({
  components: {
    PcHeader,
    TabBar,
    Toast,
    Loading,
    Dialog,
    MNavigationBar,
  },
  setup() {
    const isLargeScreen = useMediaQuery('(min-width: 750px)');
    const { isShowTabbar } = useTabbar();
    const showTabbar = computed(
      () => isShowTabbar.value && !isLargeScreen.value
    );
    const showNavbbar = computed(() => isLargeScreen.value);
    const { isLogin } = useUser();
    const isShowPhoneNavBar = ref(false);
  
    // const router = useRouter();
    watch(
      () => showTabbar,
      (v1, v2) => {
        if (v1 && v2) {
          isShowPhoneNavBar.value = !v1.value && isMobile();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    onBeforeMount(() => {
      getExtraParams();
      // if (isBytedanceWebview() && router.currentRoute.value.name != 'Tips') {
      //   router.push({ name: 'Tips' });
      //   return;
      // }
      if (isLogin.value) {
        fetchUserInfo();
      }
    });
    
    return {
      isLogin,
      showNavbbar,
      showTabbar,
      isShowTabbar,
      isLargeScreen,
      isShowPhoneNavBar,
    };
  },

});
