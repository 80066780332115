import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e226fde"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "item-icon-base"
}
const _hoisted_2 = {
  key: 1,
  class: "item-icon-base"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "tab-bar-item",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.itemClick && _ctx.itemClick(...args)))
  }, [
    (!_ctx.isActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "item-icon", {}, undefined, true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "item-icon-active", {}, undefined, true)
        ])),
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.activeStyle)
    }, [
      _renderSlot(_ctx.$slots, "item-text", {}, undefined, true)
    ], 4)
  ]))
}