
import { defineComponent, ref } from 'vue';
import TabBarItem from '@/components/common/tabbar/tab-bar-item.vue';
import { TABBAR_CONFIG } from '@/config/tabbar';
export default defineComponent({
  name: 'PcHeader',
  components: {
    TabBarItem,
  },
  setup: () => {
    const tabbarConfig  = ref(TABBAR_CONFIG);
    return {
      tabbarConfig,
    };
  },
});
