
import { computed, defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  props: {
    path: {
      type: String,
      default: '',
    },
    activeColor: {
      type: String,
      default: 'red',
    },
  },
  setup: (prop) => {
    const route = useRoute();
    const router = useRouter();
    const isActive = computed(() => route.name === prop.path);
    const activeStyle = computed(() => (isActive.value ? { color: prop.activeColor } : {}));

    return {
      isActive,
      activeStyle,
      itemClick() {
        router.replace({
          name: prop.path,
        });
      },
    };
  },
});
