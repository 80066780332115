import { ref, Ref, onBeforeMount, computed } from 'vue';
import { getCountryConfig } from '@/api/country';
import { CountryItem } from '@/types/country';
import { promiseCache } from '@/libs/utils';

const cacheCountryConfig = promiseCache(getCountryConfig);

export const useCountry = () => {
  const countryList: Ref<CountryItem[]> = ref([]);

  const getCountryInfoById = (id: string): CountryItem | undefined => countryList.value.find(item => item.id === id);

  const pickerOptions = computed(() => {
    const arr = countryList.value.map(item => ({
      label: item.nameCn,
      value: item.id,
      name: item.nameCn,
      icon: item.icon,
    }));
    return arr;
  });

  onBeforeMount(async () => {
    countryList.value = await cacheCountryConfig();
  });

  return {
    countryList,
    pickerOptions,
    getCountryInfoById,
  };
};
