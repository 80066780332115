import { App } from 'vue';
import { createI18n } from 'vue-i18n';
import { getLang } from './lang';
import en from '@/i18n/en/main.json';
import cn from '@/i18n/zh-ch/main.json';
import hk from '@/i18n/zh-hk/main.json';
/** 默认语言 */
const defaultLang = getLang({ key: 'lang' });

/** 从localstorage中获取用户上次 */
const i18n = createI18n({
  locale: defaultLang,
  globalInjection: true,
  fallbackLocale: 'en',
  messages: {
    en,
    'zh-cn': cn,
    hk,
  },
});
export function setupI18n(app: App) {
  app.use(i18n);
}
