import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c4614b90"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabBarItem = _resolveComponent("TabBarItem")!
  const _component_TabBarContainer = _resolveComponent("TabBarContainer")!

  return (_openBlock(), _createBlock(_component_TabBarContainer, {
    onTouchmove: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabbarConfig, (item) => {
        return (_openBlock(), _createBlock(_component_TabBarItem, {
          "active-color": "#314EE0",
          path: item.routeName,
          key: item.routeName
        }, {
          "item-icon": _withCtx(() => [
            _createElementVNode("img", {
              src: item.iconPath,
              class: "tabbar-icon"
            }, null, 8, _hoisted_1)
          ]),
          "item-icon-active": _withCtx(() => [
            _createElementVNode("img", {
              src: item.selectedIconPath,
              class: "tabbar-icon"
            }, null, 8, _hoisted_2)
          ]),
          "item-text": _withCtx(() => [
            _createElementVNode("div", null, _toDisplayString(item.text), 1)
          ]),
          _: 2
        }, 1032, ["path"]))
      }), 128))
    ]),
    _: 1
  }))
}