/** 用户信息 */
export type UserInfo = {
  id: number;
  code: string;
  parentId: number | null;
  balance: number;
  grandParentId: number | null;
  distributorLevelId: number | null;
  mobile: string | null;
  info: WxUserInfo;
  name: string;
  role: number;
  orderAmount: number;
  checkedCourse: number;
  point: number;
  status: number;
  userCode: string;
  parentInfo: {
    image: string | null;
    name: string | null;
    parent_code: string | null;
  };
  hasOrder: number;
};

/** 微信相关信息 */
export type WxUserInfo = {
  avatarUrl: string;
  city: string;
  country: string;
  language: string;
  nickname: string;
  openid: string;
  privilege: [];
  province: '';
  sex: Sex;
  unionid: string;
};

/** 用户性别 */
export enum Sex {
  /** 男 */
  Male = 0,
  /** 女 */
  Female = 1,
}

/** 手机号码类别 */
export enum SmsType {
  /** 中国大陆 */
  CN = '1',
  /** 国际 */
  Other = '2',
}
export const SmsTypeText = {
  [SmsType.CN]: '中国大陆',
  /** 国际 */
  [SmsType.Other]: '国际',
};
/** 验证请求体信息 */
export type SmsCodeParams = {
  mobile: string;
  smsType: SmsType;
};

/** 绑定手机请求体信息 */
export type BindMobileParams = {
  mobile: string;
  verifyCode: string;
};
