
import { defineComponent } from 'vue';
import { useDialog } from '../../hooks/dialog';
export default defineComponent({
  name: 'Dialog',
  setup: () => {
    const { isShowDialog, contentText, btnsArr, hideDialog } = useDialog();
    return {
      isShowDialog,
      contentText,
      clickBtn: async (fn) => {
        if (fn) {
          await fn();
        }
        hideDialog();
      },
      btnsArr,
    };
  },
});
