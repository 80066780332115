/* 元转成分*/
export const yuan2Fen = function (value: any): string {
  // eslint-disable-next-line
  const re = /^[\+|-]?\d+(\.\d+)?$/;
  let valueStr: string;
  if (typeof value !== 'string') {
    valueStr = value ? value.toString() : '0';
  } else {
    valueStr = value ? value : '0';
  }
  return re.test(valueStr) ? (parseFloat(valueStr) * 100).toFixed(0) : '0';
};

/* 分转成元*/
export const fen2Yuan = function (value: any, fix?: number): string {
  // eslint-disable-next-line
  const re = /^[\+|-]?[0-9]+$/;
  let valueStr: string;
  if (typeof value !== 'string') {
    valueStr = value ? value.toString() : '0';
  } else {
    valueStr = value ? value : '0';
  }
  return re.test(valueStr)
    ? (parseFloat(valueStr) / 100).toFixed(fix || 2)
    : '0.00';
};

/** promiseCache */
export const promiseCache = (fn: Function) => {
  let promiseCache: Promise<any>;
  return (...args) => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (!promiseCache) {
      promiseCache = fn(...args);
    }
    return promiseCache;
  };
};

/**
 * Format unix timestamp to ISO8601
 * @param formatStr yyyy mm dd h m s
 * @param timestamp
 */
export const formatDate = (formatStr: string, timestamp: number): string => {
  const a = new Date(timestamp * 1000);
  const o: { [key in string]: any } = {
    'y+': a.getFullYear(),
    'm+': a.getMonth() + 1, // 月份
    'd+': a.getDate(), // 日
    'h+': a.getHours(), // 小时
    'i+': a.getMinutes(), // 分
    's+': a.getSeconds(), // 秒
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const k in o) {
    if (new RegExp(`(${k})`, 'i').test(formatStr)) {
      // eslint-disable-next-line no-param-reassign
      formatStr = formatStr.replace(
        RegExp.$1,
        o[k].toString().length < 2 ? `0${o[k]}` : o[k]
      );
    }
  }
  return formatStr;
};

export const isWechat = () =>
  /MicroMessenger/i.test(window.navigator.userAgent);

export const isMobile = (): boolean =>
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/.test(
    window.navigator.userAgent
  );

export const isBytedanceWebview = () =>
  /BytedanceWebview/i.test(window.navigator.userAgent);

/** 是否 Tik Tok 打开*/
export const isTikTok = () => /musical_ly/i.test(window.navigator.userAgent);

/** 是否在 支付宝客户端 webview 打开*/
export const isAlipay = () => /Alipay/i.test(window.navigator.userAgent);

export const sleep = (s: number) =>
  new Promise((resolve) => setTimeout(resolve, s));
