import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useUrlSearchParams } from '@vueuse/core';
import { App } from 'vue';
export const ROUTES: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    meta: {
      showTabbar: true,
      keepAlive: true,
    },
    component: () => import(/* webpackChunkName: "main" */ '../views/home.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/login.vue'),
  },
  {
    path: '/tiktok-login',
    name: 'TikTokLogin',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/tiktok-login.vue'),
  },
  {
    path: '/tips',
    name: 'Tips',
    meta: {
      showTabbar: false,
      keepAlive: false,
    },
    component: () => import(/* webpackChunkName: "main" */ '../views/tips.vue'),
  },
  {
    path: '/order',
    name: 'Order',
    meta: {
      showTabbar: true,
      keepAlive: true,
    },
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/order.vue'),
  },
  {
    path: '/addressList',
    name: 'AddressList',
    component: () =>
      import(/* webpackChunkName: "address" */ '../views/address-list.vue'),
  },
  {
    path: '/addressEdit',
    name: 'AddressEdit',
    component: () =>
      import(/* webpackChunkName: "address" */ '../views/address-edit.vue'),
  },
  {
    path: '/orderProtocol',
    name: 'OrderProtocol',
    component: () =>
      import(/* webpackChunkName: "other" */ '../views/order-protocol.vue'),
  },
  {
    path: '/orderList',
    name: 'OrderList',
    meta: {
      showTabbar: true,
      keepAlive: true,
    },
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/order-list.vue'),
  },
  {
    path: '/orderComplete',
    name: 'OrderComplete',
    meta: {
      showTabbar: true,
    },
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/order-complete.vue'),
  },
  {
    path: '/orderDetail',
    name: 'OrderDetail',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/order-detail.vue'),
  },
  {
    path: '/orderSub',
    name: 'OrderSub',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/order-sub.vue'),
  },
  {
    path: '/mine',
    name: 'Mine',
    meta: {
      showTabbar: true,
      keepAlive: true,
    },
    component: () => import(/* webpackChunkName: "main" */ '../views/mine.vue'),
  },
  {
    path: '/rule',
    name: 'Rule',
    component: () => import(/* webpackChunkName: "main" */ '../views/rule.vue'),
    meta: {
      title: '转运规则与说明',
    },
  },
  {
    path: '/wareHouseList',
    name: 'WareHouseList',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/ware-house-list.vue'),
    meta: {
      title: '中转仓地址',
    },
  },
  {
    path: '/myTeam',
    name: 'MyTeam',
    component: () => import(/* webpackChunkName: "main" */ '../views/team.vue'),
  },
  {
    path: '/tradeList',
    name: 'TradeList',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/trade-list.vue'),
    meta: {
      title: '账单明细',
    },
  },
  {
    path: '/expressRate',
    name: 'ExpressRate',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/express-rate.vue'),
    meta: {
      title: '价格测算',
    },
  },
  {
    path: '/charge',
    name: 'Charge',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/charge.vue'),
  },
  {
    path: '/chargeCheckout',
    name: 'ChargeCheckout',
    meta: {
      showTabbar: true,
    },
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/charge-checkout.vue'),
  },
  {
    path: '/chargeSuccess',
    name: 'ChargeSuccess',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/charge-success.vue'),
  },
  {
    path: '/chargeFail',
    name: 'ChargeFail',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/charge-fail.vue'),
  },
  {
    path: '/productList',
    name: 'ProductList',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/product/list.vue'),
  },
  {
    path: '/productEdit',
    name: 'ProductEdit',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/product/edit.vue'),
  },
  {
    path: '/myCoupon',
    name: 'MyCoupon',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/coupon.vue'),
  },
  {
    path: '/coupondetail',
    name: 'CouponDetail',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/coupon-detail.vue'),
  },
  {
    path: '/share',
    name: 'share',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/share.vue'),
  },
  {
    path: '/orderComfirm',
    name: 'OrderComfirm',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/order-comfirm.vue'),
  },
  {
    path: '/checkout',
    name: 'OrderCheckout',
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/checkout.vue'),
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes: ROUTES,
  scrollBehavior() {
    return { top: 0 };
  },
});

export const getTargetPageFormSearch = () => {
  const s = location.search || '';
  const rt = s.match(/page=([^&]*)(&|$)/);
  if (rt) {
    const match = rt[1];
    return match.replace(/%2F/g, '/');
  }
  // 默认都回到首页
  return undefined;
};
export function setupRouter(app: App) {
  app.use(router);
  const { page, ...params } = useUrlSearchParams('history');
  console.log(params);
  // const hash = getTargetPageFormSearch();
  if (page) {
    router.replace({
      name: page as string,
      query: params,
    });
  }
}
export default router;
