import { getInitInfo } from '@/hooks/login';
import { request } from '@/libs/request';
import { sleep } from '@/libs/utils';

export const loginWx = async (code: string, scene?: string) => {
  const source = localStorage.getItem('source') || undefined;
  const extra = localStorage.getItem('extra') || undefined;
  const res = await request({
    url:
      scene && scene === 'wechatQrcode'
        ? '/v1/user/wechat/qrcode-login'
        : '/v1/user/wechat/web/login',
    method: 'POST',
    data: {
      code,
      source,
      extra,
    },
  });
  await googleReport(res);
  return {
    checkedCourse: res.checked_course,
    hasMobile: res.has_mobile,
    token: res.token,
    userId: res.user_id,
  };
};

export const loginGoogle = async (token: string) => {
  const source = localStorage.getItem('source') || undefined;
  const extra = localStorage.getItem('extra') || undefined;
  const res = await request({
    url: '/v1/user/google/web/login',
    method: 'POST',
    data: {
      access_token: token,
      source,
      extra,
    },
  });
  await googleReport(res);
  return {
    token: res.token,
  };
};

export const initWechatQrcode = async () => {
  const { appid, scope } = await getInitInfo();
  const redirectUrl = `${location.origin}/login?scene=wechatQrcode`;
  // const redirectUrl = `https://app.gblinker.com/login?source=wechatQrcode`;
  new WxLogin({
    self_redirect: false,
    id: 'wechat-qrcode',
    appid,
    scope,
    redirect_uri: encodeURIComponent(redirectUrl),
    state: +new Date(),
    style: 'white',
    href: '//res-cn.public.gblinker.com/gblinker/merchants/styles/qrcode.css',
  });
};

/**
 * tiktok 登录
 * @param code
 * @returns
 */
export const loginTikTok = async (code: string) => {
  const source = localStorage.getItem('source') || undefined;
  const extra = localStorage.getItem('extra') || undefined;
  const res = await request({
    url: '/v1/user/tiktok/login',
    method: 'POST',
    data: {
      code,
      source,
      extra,
    },
  });
  await googleReport(res);
  return {
    checkedCourse: res.checked_course,
    hasMobile: res.has_mobile,
    token: res.token,
    userId: res.user_id,
  };
};
/**
 * 手机号登录
 * @param mobile
 * @param verify_code
 * @returns
 */
export const mobileLogin = async (mobile: string, verify_code: string) => {
  const source = localStorage.getItem('source') || undefined;
  const extra = localStorage.getItem('extra') || undefined;
  const res = await request({
    url: '/v1/user/mobile/login',
    method: 'POST',
    data: {
      mobile,
      verify_code,
      source,
      extra,
    },
  });
  await googleReport(res);
  return {
    checkedCourse: res.checked_course,
    hasMobile: res.has_mobile,
    token: res.token,
    userId: res.user_id,
  };
};

export const googleReport = async (res: any) => {
  if (res?.is_first_login && res.is_first_login == 1) {
    await gtag('event', 'conversion', {
      send_to: 'AW-10948761422/FXzYCN3ppc0DEM6u4-Qo',
    });
    await sleep(1000);
  }
};
