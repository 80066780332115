import { ref, Ref } from 'vue';
export enum ToastTypeIconClass{
  success = 'success-no-circle',
  warn = 'warn',
  error = 'error'
}
const isShowToast = ref(false);
const toastText = ref('加载中');
const iconClass: Ref<string> = ref(ToastTypeIconClass.success);

export const useToast = () => {
  const showToast = (text?: string, type?: ToastTypeIconClass) => {
    iconClass.value = type || ToastTypeIconClass.success;
    toastText.value = text || '加载中';
    isShowToast.value = true;
    setTimeout(() => {
      hideToast();
    }, 1000);
  };
  const hideToast = () => {
    isShowToast.value = false;
  };
  return {
    iconClass,
    isShowToast,
    showToast,
    hideToast,
    toastText,
  };
};

// export const install = (app: App) => {
//   const { showToast, hideToast } = useToast();
//   app.config.globalProperties.showToast = showToast;
//   app.config.globalProperties.hideToast = hideToast;
// };
