import { onBeforeMount, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { TABBAR_CONFIG } from '@/config/tabbar';
import { ROUTES } from '@/router/index';
export const isMatchTabbar = (key: string) => {
  const showTabbarRouteList = ROUTES.filter(item => item.meta?.showTabbar).map(item => item.name);
  return showTabbarRouteList.includes(key!);
};
/** useTabbar */
export const useTabbar = () => {
  const route = useRoute();
  const tabbarConfig = ref(TABBAR_CONFIG);
  const isShowTabbar = computed(() => isMatchTabbar(route.name as string));
  onBeforeMount(() => {

  });
  return {
    tabbarConfig,
    isShowTabbar,
  };
};
