import { ref, Ref } from 'vue';
type BtnArray = {text: string, fn?: any}[];
const isShowDialog = ref(false);
const hideDialog = () => {
  isShowDialog.value = false;
};
const defaultBtn = [{
  text: 'OK',
  fn: hideDialog,
}];
const contentText = ref('');
const btnsArr: Ref<BtnArray> = ref(defaultBtn);

const showDialog = (data?: {
  content: string,
  btns?: BtnArray}) => {
  contentText.value = '加载中';
  btnsArr.value = defaultBtn;
  if (data) {
    contentText.value = data?.content || '加载中';
    btnsArr.value = data?.btns || defaultBtn;
  }
  isShowDialog.value = true;
};
export const useDialog = () => ({
  isShowDialog,
  showDialog,
  hideDialog,
  contentText,
  btnsArr,
});
