import { useUrlSearchParams } from '@vueuse/core';

/**
 * 从指定的范围中选择语言，如果没有，则返回默认语言
 * 不做具体的转换逻辑
 * @param selectArr
 * @param defaultLang
 * @returns {*
 */
function getLang({ key }: {
  key: string
}): string {
  const lang = getLangFromUrl(key) || getLangFromUa() || getLangFromNavigator();
  const formattedLang = formatLang(lang);
  return formattedLang;
}

/** 格式化url*/
function formatLang(lang: string): string {
  return lang.replace(/_/g, '-');
}

/**
 * 优先从url获取语言，否则从useragent中取
 * @param rang
 * @param defaultLang
 * @param key
 * @returns {*}
 */
function getLangFromUrl(key = 'lang'): string {
  const val = useUrlSearchParams('history')[key];
  if (Array.isArray(val)) {
    return val[0];
  }
  return val;
}

/**
 * 从ua获取语言 适用于微信内部webview
 * @returns {*}
 */
function getLangFromUa() {
  const matchResult = typeof navigator === 'object' ? navigator.userAgent.match(/\s*Language\/([^\s]+)/) : '';
  if (!matchResult) {
    return '';
  }
  return matchResult[1].toLowerCase();
}

/**
 * 从navigator中获取
 * @returns {string}
 */
function getLangFromNavigator() {
  return typeof navigator === 'object' ? navigator.language.toLowerCase() : '';
}

export {
  getLang,
  getLangFromUrl,
  getLangFromNavigator,
  getLangFromUa,
};
