import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PcHeader = _resolveComponent("PcHeader")!
  const _component_MNavigationBar = _resolveComponent("MNavigationBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_TabBar = _resolveComponent("TabBar")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-container", { 'large-bg': _ctx.isLargeScreen }])
  }, [
    (_ctx.showNavbbar)
      ? (_openBlock(), _createBlock(_component_PcHeader, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.showNavbbar && _ctx.isShowPhoneNavBar)
      ? (_openBlock(), _createBlock(_component_MNavigationBar, { key: 1 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["app-content", [
        { 'show-tabbar': _ctx.showTabbar },
        { 'show-navbar': _ctx.showNavbbar },
        { 'show-phone-navbar': !_ctx.showNavbbar && _ctx.isShowPhoneNavBar },
      ]])
    }, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_ctx.$route.meta.keepAlive)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  key: _ctx.$route.name
                }))
              : _createCommentVNode("", true)
          ], 1024)),
          (!_ctx.$route.meta.keepAlive)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                key: _ctx.$route.name
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_ctx.showTabbar)
        ? (_openBlock(), _createBlock(_component_TabBar, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_Loading),
      _createVNode(_component_Toast),
      _createVNode(_component_Dialog)
    ], 2)
  ], 2))
}