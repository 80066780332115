import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
// import { jumpToAuthPage } from '@/hooks/login';
import { isMatchTabbar } from '@/hooks/tabbar';

import { router } from '@/router';
import { useUser } from '@/hooks/user';
const resHandle = [(args: AxiosResponse) => args];
const errHandle = [(args: AxiosError) => {
  console.log('request error', args);
  if (args.response?.status === 401) {
    const { setToken } = useUser();
    setToken('');
    if (isMatchTabbar(router.currentRoute.value.name as string)) {
      throw Promise.reject('unlogin');
    }
    router.replace({
      name: 'Login',
      query: {
        redirect: router.currentRoute.value.fullPath,
      },
    });
    throw 'unlogin';
  }
  let message = '';
  if (Array.isArray(args.response?.data.message)) {
    message = args.response?.data.message[0];
  } else if (typeof args.response?.data.message === 'string') {
    message = args.response?.data.message;
  } else {
    message = 'system busy';
  }
  throw message;
}];

const requestInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000,
  headers: {
    authorization: `Bearer ${localStorage.getItem('token')}`,
  },
  validateStatus: (status: number) => [200, 201].includes(status),
});

requestInstance.interceptors.response.use(...resHandle, ...errHandle);

export const request = async (data: AxiosRequestConfig) => {
  const res = await requestInstance.request(data);
  return res?.data;
};
