
import { defineComponent } from 'vue';
import { useLoading } from '../../hooks/loading';
export default defineComponent({
  name: 'Loading',
  setup: () => {
    const { isShowLoading, loadingText } = useLoading();
    return { isShowLoading, loadingText };
  },
});
